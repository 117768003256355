@import '~antd/dist/antd.less';

.app-container {
  height: 100vh;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
  min-width: 252px;
}

.site-layout-background {
  overflow-y: scroll;
}

.error-message {
  color: red;
  display: flex;
  justify-content: center;
}

.content-header {
  display: block;
  margin-bottom: 24px;

  .icon {
    margin-right: 8px;
  }

  .ant-btn {
    float: right;
  }
}

.content-form {
  max-width: 500px;
}

.button-group {
  padding-top: 24px;
  display: block;
}

.button-group,
.form-button {
  Button {
    width: 100px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.form-button {
  margin-top: 12px;
}

.breadcrumb {
  padding-bottom: 24px !important;
}

.ant-row-style {
  cursor: pointer;
}

.app-form-container {
  max-width: 1024px;
  padding: 28px;
  margin: 20px auto;
  border-radius: 5px;
  background-color: white;

  .app-form-horizontal-fields {
    display: flex;

    >div {
      flex: 1;
    }

    >div:not(:last-child) {
      padding-right: 16px;
    }
  }

  @media (max-width: 768px) {
    max-width: 680px;
    margin-top: 0;
  }

  @media (max-width: 576px) {
    margin: auto;

    .app-form-horizontal-fields {
      flex-direction: column;

      >div:not(:last-child) {
        padding-right: 0;
      }
    }
  }
}

.app-form-small-container {
  &:extend(.app-form-container);
  max-width: 576px;
}

.ant-pagination {
  text-align: right;
}

.filter-panel {
  margin-bottom: 8px;

  >div {
    margin: 8px !important;
  }

  .filter-button-group {
    width: 100%;

    Button {
      width: 100px !important;
      margin-right: 8px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.long-button {
  min-width: 150px !important;
}

.statistic-container {
  background-color: @primary-2;
  padding: 4px 8px;
  margin-top: 8px;

  span {
    font-weight: bold;
  }
}

.ant-paragraph {
  margin-bottom: 0px !important
}

@media only screen and (min-width:993px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 40% !important;
  }
}

@media only screen and (max-width:992px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 60% !important;
  }
}

@media only screen and (max-width: 768px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 80% !important;
  }
}

@primary-color: #046CC1;@link-color: #1890ff;